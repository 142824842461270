export const environment = {
  test: false,
  production: true,
  cookieUrl: '//assets.adobedtm.com/5dfc7d97c6fb/9f2b9af520c3/launch-b25a29efa7a1-staging.min.js',
  apiURL: 'https://int.lcas.horizonint.cloud/api',
  apiUrlVersion: 'v2',
  userApi: 'https://prodint.um.user.horizonint.cloud/api',
  userApiVersion: 'v2',
  subscriptionApi: 'https://product-int.subscription.horizonint.cloud/api',
  subscriptionApiVersion: 'v2',
  pointApi: 'https://product-int.point.horizonint.cloud/api/v2',
  pointApiVersion: 'v2',
  locationApi: 'https://product-int.locations.horizonint.cloud',
  locationApiVersion: 'v2',
  deviceApi: 'https://product-int-dm.device.horizonint.cloud/api',
  deviceApiVersion: 'v2',
  edgeApi: 'https://productint.hedge.horizonint.cloud/api',
  edgeApiVersion: 'v1',
  aodsApi:'https://product-int.aods.horizonint.cloud/api',
  aodsApiVersion:'v2',
  product: 'lcas-app',
  clientId: 'aM71E40a0pDJuuLo2XRDWSfmV1PO1huK',
  audience: 'https://horizonint.cloud',
  issuer: 'https://siemens-qa-bt-015.eu.auth0.com/',
  msIssuer: 'https://btprodeu.piam.eu1.mindsphere.io/oauth/token',
  siemensIdBaseUrl: 'https://login-qa.siemens.com',
  tenant: 'main-tenant-oidc',
  accountManagerAppUrl: 'https://account.apps.horizonint.cloud',
  devEnvAccountManagerAppUrl: 'https://account.apps.horizonint.cloud',
  assetsAppUrl: 'https://assets.apps.horizonint.cloud',
  devEnvAssetsAppUrl: 'https://assets.apps.horizonint.cloud',
  mediaBaseUrl: '',
  subscriptionManagerAppUrl: 'https://siemens-sandbox.subscription-suite.io',
  lcasAppVersion: 'v1-3-9',
  googleMapsApiKey: 'AIzaSyCUdGQXVSeeCE8-ek8gKyBUckIE3FvOacc',
  scanditApiKey: 'AY2iRgz5KfiYNGj3WDvMtPNDU60tQx6+2HzcQWkxp6jhUyQz2k+cyaVUD5mRLBead3iqHyN0wJrcXCYkokecVshmFTCBaKrnIyBVS1kLZP8verM2IEiKIqNpN/2zYPHo3USh+fVKhv3betIbSkeaoYFJmPHSVoPYKRMNBEhGsDMRdPEsIDdPKThtc5f/Ei/ENTTlXOI1cduDGSPZQ4ygg55wtaQfjx5UevC9TC8GI6Hra9RS+h3Dk90Q5thxh9O1MnAotxjQXaPnQL6PlvPem+++kpftVPzu5H0xsYuIa60rkB3M4z0n2MDtMTlYUrk7judlLQPyvz6JVKn8PzNoNkswR5gcHR1tKXS13QOIRVirvg7rMWqxUtiZMW7NesDPA1dusjxRs5QyQI9A8PVfC6Ro0rNZl1zF3TnmK93j9DyfMFqDu31p3rEVHGQ0pMvYct+wlLT1KwBn4dEvsOgqsrd0NrCBNMsWCQRuYxp60Y4sWvHWfbD3tJraJLg61ElMttV+QgHTfs+Mquhk4Caab8ijkZK2p22eVuE8l0f2bKPtHfHkwTO0FTh1leuULhY7tZNjPC6rOMKj3U+smElDPb0+Yxe16OWgL8gfmxVRR15wu70WDbZzyMBSsE/8p99kEkszGZ2u09f0C2r/vciEGxK5KSZZLPlP1FjBBossbFGElN4gplD3tesWY7YJstWFtXTw4PDIsBAuka2n/VaEGH2Fa3SWmqXNCZJWLv8MM2R9lD58wSwYAbfkqD3sOgkBLRRL4oftxLHjInsOArJiGYIRK+4VbcNMlgDRI1F9JiGZsCkFktDiLQOaWczFkawl1j5FWIEgSZ8msesgZ1iaFvqMZsTCWRPOPPkcD7pMJXS7xR0hk1TcW79Rf0s=',
  isChinaDeployment: '',
  pipelineEnvironment: 'int',
  supportedLanguages: 'en,de,it,fr'
};
